<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        id: string;
        open: boolean;
        right?: boolean;
    }>(),
    {
        open: false,
        right: false,
    },
);

watch(
    () => props.open,
    () => {
        if (props.open) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    },
);

const emit = defineEmits(['change']);
</script>

<template>
    <div class="drawer z-[50]" :class="{'drawer-end': right}">
        <input :id="id" type="checkbox" class="drawer-toggle" :checked="open" @change="emit('change', $event)"/>
        <div class="drawer-side top-[68px] left-0 bottom-0 overflow-hidden">
            <label :for="id" class="drawer-overlay"></label>
            <div class="scrollable-drawer">
                <div class="scrollable-content">
                    <div class="flex md:mb-5 p-0 md:p-5 flex-row justify-between items-center align-middle">
                        <label
                            :for="id"
                            class="btn btn-lg btn-circle bg-transparent shadow-none hover:bg-transparent border-none ease-in-out duration-500 hover:animate-wiggle"
                            :class="{'ms-auto': !right}"
                        >
                            <img src="/icon.png" :class="{'rotate-180': !right}" alt="Close profile"/>
                        </label>
                        <h2 class="text-2xl text-center pe-5">
                            <slot name="title" />
                        </h2>
                    </div>
                    <div class="px-5 md:px-10">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="postcss">
.scrollable-drawer {
    @apply overflow-y-scroll w-full h-full md:w-4/5 lg:w-[800px] pb-[68px];
    -webkit-overflow-scrolling: touch;

    .scrollable-content {
        @apply bg-base-100 min-h-full pb-10;
        min-height: 100vh;
    }
}
</style>
